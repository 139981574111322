import { alpha, Stack, Typography, useTheme } from '@mui/material'

import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import Chart from './Chart'
import type { OccupationAgeStatistics as OccupationAgeStatisticsType } from 'src/features/Yrke/types/OccupationStatistics.types'
import { useEpiContent } from '@trr/app-shell-data'
import { useTranslation } from 'react-i18next'
import { Content } from 'src/App/App.types'
import convertToStatistics from 'src/utils/helpers/convertToStatistics'

interface OccupationAgeStatisticsProps {
  occupationAgeStatistics: OccupationAgeStatisticsType
  horisontal: boolean
}

const OccupationAgeStatistics = ({
  occupationAgeStatistics,
  horisontal,
}: OccupationAgeStatisticsProps) => {
  const { palette } = useTheme()
  const {
    yrke: { ageHeader, agePreamble },
  } = useEpiContent<Content>()

  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tScbStatistik } = useTranslation('translation', {
    keyPrefix: 'scbStatistik',
  })

  const ageLabelsAndColors = [
    { label: tScbStatistik('age1'), color: palette.primary.main, key: 'age1' },
    {
      label: tScbStatistik('age2'),
      color: palette.accent?.dark ?? '',
      key: 'age2',
    },
    {
      label: tScbStatistik('age3'),
      color: palette.secondary.main,
      key: 'age3',
    },
    { label: tScbStatistik('age4'), color: '#3560FF', key: 'age4' },
    { label: tScbStatistik('age5'), color: palette.error.main, key: 'age5' },
  ]
  const ageChartData = convertToStatistics(
    { ...occupationAgeStatistics },
    ageLabelsAndColors
  )

  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''

  if (!occupationAgeStatistics) return null

  return (
    <Stack
      sx={{
        backgroundColor: palette.surface?.blue,
        p: { xs: 3, sm: 5 },
        mt: { xs: 0, sm: 3 },
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: 0, sm: 2 }}
      flex={{ xs: '1', md: horisontal ? 'auto' : '1' }}
      direction="column"
      justifyContent="space-between"
    >
      <Stack
        direction={{ xs: 'column', md: horisontal ? 'column' : 'row' }}
        gap="8px  40px"
        height="100%"
      >
        <Stack
          flex={{
            md: horisontal ? 'auto' : '2',
            lg: horisontal ? 'auto' : '1.2',
          }}
        >
          <Typography variant="h4" component="h3">
            {ageHeader}
          </Typography>
          <Typography variant="body1" component="p">
            {agePreamble}
          </Typography>
        </Stack>
        <Chart data={ageChartData} horisontal={horisontal} />
      </Stack>
      <SourceMetaText
        text={`${tKallor('kallaSCB')} (${occupationAgeStatistics.year?.toString() ?? ''}).`}
      />
    </Stack>
  )
}

export default OccupationAgeStatistics
