import kompetensApi from '../kompetensApi'

interface FormatTextRequest {
  id: string
  text: string
  lastUpdated?: string
}
interface FormatTextResponse {
  id: string
  text: string
  summary?: string
}

export const competenceApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    postFormatText: build.mutation<FormatTextResponse, FormatTextRequest>({
      query: (body) => {
        return {
          timeout: 60000, // Needed because this request sometimes takes a really long time
          url: `/prognosportal/formattext/`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { usePostFormatTextMutation } = competenceApi
