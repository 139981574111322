import React, { ReactElement, ReactNode, UIEvent } from 'react'
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  type PopperProps,
  Stack,
  Typography,
  styled,
} from '@mui/material'

import type { Content } from 'src/App/App.types'
import { useTranslation } from 'react-i18next'

const FilterWrapper = styled(Box)(({ theme }) => ({
  background: 'white',
  width: 386,
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
  display: 'inline-block',
  marginTop: theme.spacing(1),
  borderRadius: theme.spacing(1),
}))

const Content = styled(Stack)(({ theme }) => ({
  maxHeight: 670,
  padding: theme.spacing(0, 2, 0),
  overflowY: 'auto',
}))

const PopperHeader = styled(Box)<{ isscrolling: string }>(
  ({ theme, isscrolling }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderBottom: '1px solid',
    borderColor: isscrolling ? theme.palette.divider : 'transparent',
    transition: 'all 0.2s ease',
  })
)

const PopperFooter = styled(Stack)<{ isscrollable: string }>(
  ({ theme, isscrollable }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid',
    borderColor: isscrollable ? theme.palette.divider : 'transparent',
    transition: 'all 0.2s ease',
  })
)
export interface CustomPopperProps extends PopperProps {
  label: string
  headerIcon: ReactNode
  open: boolean
  anchor: HTMLElement
  children: ReactElement
  footer?: ReactNode
  onClickHeaderIcon: () => void
  onClickAway: () => void
}

export const CustomPopper = ({
  label,
  headerIcon,
  onClickHeaderIcon,
  footer,
  open,
  anchor,
  onClickAway,
  children,
  ...props
}: CustomPopperProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'filtrering',
  })
  const [isScrolling, setIsScrolling] = React.useState(false)

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    setIsScrolling((event.target as HTMLDivElement)?.scrollTop !== 0)
  }

  return (
    <>
      <Popper
        open={open}
        placement="bottom-start"
        anchorEl={anchor}
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
        ]}
        sx={{ zIndex: 3 }}
        {...props}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <FilterWrapper>
            <PopperHeader isscrolling={isScrolling.toString()}>
              <Typography variant="body1" fontWeight="bold">
                {label}
              </Typography>
              <IconButton
                onClick={onClickHeaderIcon}
                sx={{ position: 'absolute', right: 15 }}
                aria-label={t('stang')}
              >
                {headerIcon}
              </IconButton>
            </PopperHeader>
            <Content onScroll={handleScroll}>{children}</Content>
            <PopperFooter isscrollable={isScrolling.toString()}>
              {footer}
            </PopperFooter>
          </FilterWrapper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
