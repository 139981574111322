import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'

interface OutlookNullStateProps {
  onLinkClick: () => void
}

const OutlookNullState: React.FC<OutlookNullStateProps> = ({ onLinkClick }) => {
  const { t: tAfStatistik } = useTranslation('translation', {
    keyPrefix: 'afStatistik',
  })
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="200px"
      bgcolor="#F3F4F6"
      borderRadius={2}
      p={3}
      data-testid="outlook-null-state"
    >
      <InfoIcon sx={{ fontSize: 40, color: 'black', mb: 2 }} />
      <Typography variant="body1" align="center" gutterBottom>
        {tAfStatistik('regionPrognosSaknas')}
      </Typography>
      <Link
        component="button"
        variant="body2"
        onClick={onLinkClick}
        sx={{
          color: '#6D28D9',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
        }}
      >
        {tAfStatistik('seHelaLandet')}
      </Link>
    </Box>
  )
}

export default OutlookNullState
