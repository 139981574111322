import { Education } from 'src/features/Utbildning/types/Education.types'
import kompetensApi from '../kompetensApi'
import { EducationsMatchByOccupationResponse } from 'src/features/Utbildning/types/EducationsMatchByOccupationResponse.types'
import { EducationsResponse } from 'src/features/Utbildning/types/EducationsResponse.types'
import { PostEducationsRequest } from 'src/features/Utbildning/types/PostEducationsRequest.types'

export const educationsApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    educationsSearch: build.query<EducationsResponse, PostEducationsRequest>({
      query: (formData) => ({
        url: '/prognosportal/educations/',
        method: 'POST',
        body: formData,
      }),
      transformResponse: (
        response: EducationsResponse
      ): EducationsResponse => ({
        hits: response.hits,
        page: response.page,
        educations:
          response.educations && response.educations.length > 0
            ? response.educations.map((el: Education) => ({
                ...el,
                eventInformation: {
                  ...el.eventInformation,
                  startDate: el.startDate ?? '',
                  endDate: el.endDate ?? '',
                  year: el.eventInformation?.year ?? '',
                  semester: el.eventInformation?.semester ?? '',
                },
              }))
            : null,
      }),
    }),
    getEducation: build.query<Education, string>({
      query: (id) => ({
        url: `/prognosportal/educations/${id}`,
      }),
    }),
    postMatchByOccupationId: build.query<
      EducationsMatchByOccupationResponse,
      string
    >({
      query: (id) => ({
        url: `/prognosportal/educations/match-by-occupation/${id}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useEducationsSearchQuery,
  useGetEducationQuery,
  usePostMatchByOccupationIdQuery,
} = educationsApi
