import React, { useState } from 'react'
import { Pagination, Stack, Typography, Box, Collapse } from '@mui/material'
import { isEmpty } from 'ramda'
import { HiddenText } from 'src/components/HiddenText/HiddenText'

import OccupationCard from '../OccupationCard/OccupationCard'
import { Occupation } from '../../types/Occupation.types'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

interface OccupationsListProps {
  occupations: Occupation[]
  header?: string
  noResult: React.ReactNode | null
  itemHeaderComponent: string
}

const OccupationsList: React.FC<OccupationsListProps> = ({
  occupations,
  header,
  noResult,
  itemHeaderComponent,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'yrken' })
  const [page, setPage] = useState<number>(0)

  return (
    <Box data-testid="occupations-list-container" sx={{ mt: 3 }}>
      {header && (
        <Typography sx={{ mb: 3 }} gutterBottom variant="h6" component="h2">
          {header}
        </Typography>
      )}
      {isEmpty(occupations) ? (
        <>{noResult}</>
      ) : (
        <>
          {!header && (
            <HiddenText>
              {occupations.length} {t('hits')}
            </HiddenText>
          )}
          <TransitionGroup>
            {occupations.slice(page * 10, page * 10 + 10)?.map((r) => (
              <Collapse key={r.id}>
                <OccupationCard
                  id={r.id}
                  headerComponent={itemHeaderComponent}
                  ssyk={r.occupationGroup?.ssyk}
                  header={r.occupationLabel}
                  subheader={r.occupationGroup?.occupationGroupLabel}
                  occupationGroupLabel={r.occupationGroup?.occupationGroupLabel}
                  occupationGroupId={r.occupationGroup?.conceptTaxonomyId}
                />
              </Collapse>
            ))}
          </TransitionGroup>
          {occupations.length > 10 && (
            <Stack
              sx={{ mt: 3 }}
              justifyContent="center"
              direction="row"
              spacing={2}
            >
              <Pagination
                data-testid="occupations-list-pagination"
                page={page + 1}
                onChange={(e, v) => {
                  setPage(v - 1)
                  window.scrollTo(0, 100)
                }}
                count={Math.ceil(occupations.length / 10)}
              />
            </Stack>
          )}
        </>
      )}
    </Box>
  )
}

export default OccupationsList
