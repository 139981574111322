import {
  ChartStatistics,
  OccupationAgeStatistics,
  OccupationEducationStatistics,
  OccupationSectorStatistics,
} from 'src/features/Yrke/types/OccupationStatistics.types'

const convertToStatistics = <
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  T extends
    | OccupationAgeStatistics
    | OccupationSectorStatistics
    | OccupationEducationStatistics,
>(
  data: T,
  labelsAndColors: { label: string; color: string; key: string }[]
): ChartStatistics[] => {
  return labelsAndColors.map((item) => ({
    label: item.label,
    percent: data[item.key as keyof T] as number,
    color: item.color,
  }))
}

export default convertToStatistics
