import { Box, CircularProgress, Typography } from '@mui/material'

interface Props {
  errorLoadingData?: boolean
  errorText?: string
  isLoading?: boolean
  headerText?: string
  children: React.ReactNode | React.ReactNode[]
}
const PageWrapper = ({
  errorLoadingData,
  isLoading,
  errorText,
  headerText,
  children,
}: Props) => {
  if (errorLoadingData) {
    return (
      <Typography
        sx={{ textAlign: 'center' }}
        color={'error'}
        variant="body1"
        data-testid="error-loading-data"
      >
        {errorText}
      </Typography>
    )
  }

  return (
    <>
      {headerText && <Typography variant="h3">{headerText}</Typography>}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress data-testid="page-wrapper-loader" />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

export default PageWrapper
