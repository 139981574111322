export interface Location {
  id: string
  name: string
  type?: LocationType
}

export enum LocationType {
  'municipality' = 'Kommun',
  'county' = 'Län',
}
