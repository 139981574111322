import { Box, Button, Collapse, TextField, Typography } from '@mui/material'
import { useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import YrkenStart from './YrkenStart'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import {
  setGetJobTitlesQuery,
  useGetJobTitlesQuery,
} from 'src/api/occupation/occupation'
import { ApplicationState } from 'src/store'
import EmptyResult from 'src/components/EmptyResult/EmptyResult'
import InputHeader from 'src/components/InputHeader/InputHeader'
import PageWrapper from 'src/components/PageWrapper/PageWrapper'
import ReliabilityBlock from 'src/components/ReliabilityBlock/ReliabilityBlock'
import { JobTitle } from 'src/types/JobTitle.types'
import OccupationCard from './components/OccupationCard/OccupationCard'
import { useTranslation } from 'react-i18next'

interface FormValues {
  search: string
}

const Yrken = () => {
  const dispatch = useDispatch()
  const trackCustomClick = useTrackCustomClick()

  const occupationSearch = useSelector<ApplicationState, string>(
    (state) => state.occupation.query
  )

  const {
    data = {} as JobTitle[],
    isError,
    isSuccess,
    isFetching,
  } = useGetJobTitlesQuery(occupationSearch, {
    skip: !occupationSearch,
  })

  useEffect(() => {
    if (isFetching) {
      trackCustomClick('Sökning', {
        label: `Yrkessökning gjordes`,
      })
    }
  }, [isFetching, trackCustomClick])

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      search: occupationSearch,
    },
    onSubmit: (values: FormValues) => {
      // We store the search query in a slice because we want to keep it when navigating from a occupation and then when the user goes back to the list again
      dispatch(setGetJobTitlesQuery(values.search))
    },
  })
  const { t } = useTranslation('translation', {
    keyPrefix: 'yrken',
  })

  return (
    <>
      <InputHeader>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              sx={{ backgroundColor: 'white', width: '100%' }}
              label={t('sokYrke')}
              id="search"
              name="search"
              type="search"
              value={values.search}
              onChange={handleChange}
            />
            <Button
              data-testid="search-button"
              type="submit"
              sx={{ marginLeft: 'auto' }}
              size="medium"
              variant="contained"
            >
              {t('sok')}
            </Button>
          </Box>
        </form>
      </InputHeader>

      <PageWrapper errorText={t('errorFetchYrke')} errorLoadingData={isError}>
        {data?.length > 0 ? (
          <>
            <Typography sx={{ mt: 1 }} variant="body2">
              {t('visar')} <b>{data.length}</b>{' '}
              {data.length > 1 ? t('yrken') : t('yrke')}
            </Typography>
            <TransitionGroup>
              {data.map((o) => (
                <Collapse key={o.occupationId}>
                  <OccupationCard
                    id={o.occupationId}
                    ssyk={o.occupationGroup.ssyk}
                    header={o.occupationLabel}
                    subheader={o.occupationGroup.occupationGroupLabel}
                    headerComponent="h2"
                    occupationGroupId={o.occupationGroup.conceptTaxonomyId}
                    occupationGroupLabel={
                      o.occupationGroup.occupationGroupLabel
                    }
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </>
        ) : (
          <Box sx={{ mt: 4 }}>
            {isSuccess ? (
              <EmptyResult
                heading={t('tomtResultat')}
                description={t('tomtResultat2')}
              />
            ) : (
              <YrkenStart />
            )}
          </Box>
        )}
      </PageWrapper>

      <ReliabilityBlock />
    </>
  )
}

export default Yrken
