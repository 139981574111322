import {
  combineReducers,
  configureStore,
  type ReducersMapObject,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import { occupationSlice } from 'src/api/occupation'
import type { OccupationSlice } from 'src/api/occupation'
import { competenceSlice } from 'src/api/competence'
import {
  EducationFilterState,
  educationFilterSlice,
} from 'src/api/slices/educationFilterSlice'
import { Competence } from 'src/types/Competence'
import { kompetensApi, referenceDataApi } from 'src/api'

export interface ApplicationState {
  educationFilter: EducationFilterState
  competence: {
    list: Competence[]
    selectedCompetences: Competence[]
  }
  occupation: OccupationSlice
}

const applicationStateReducers: ReducersMapObject<ApplicationState> = {
  educationFilter: educationFilterSlice.reducer,
  competence: competenceSlice.reducer,
  occupation: occupationSlice.reducer,
}

const rootReducer = combineReducers({
  ...applicationStateReducers,
  [kompetensApi.reducerPath]: kompetensApi.reducer,
  [referenceDataApi.reducerPath]: referenceDataApi.reducer,
})

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(kompetensApi.middleware)
        .concat(referenceDataApi.middleware),
    preloadedState,
  })

export const store = setupStore()

setupListeners(setupStore().dispatch)

export type RootState = ReturnType<typeof rootReducer>
