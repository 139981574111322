import React from 'react'
import { getAppConfig } from '@trr/app-shell-data'

import {
  getImageQuery,
  createImageAltTextFromFileName,
} from 'src/utils/helpers/getImageQuery'

const EpiImage = ({
  url,
  width = '300',
  height = 'fit-content',
}: {
  url: string
  width?: string
  height?: string
}): React.JSX.Element | null => {
  const {
    COMMON: { MEDIA_URL },
  } = getAppConfig()

  if (!url) return null

  return (
    <>
      <img
        width={width}
        src={getImageQuery(MEDIA_URL + url, 800)}
        alt={createImageAltTextFromFileName(url)}
        height={height}
        aria-hidden="true"
      />
    </>
  )
}

export default EpiImage
