import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  capitalize,
  createFilterOptions,
  useTheme,
} from '@mui/material'
import { equals } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useDebounce } from 'src/utils/hooks/useDebounce'
import { useGetCompetencesQuery } from 'src/api/competence'
import type { ApplicationState } from 'src/store'
import { Competence } from 'src/types/Competence'
import { useTranslation } from 'react-i18next'

interface SkillInputProps {
  onCompetenceSelect: (v: Competence) => void
}
const SkillInput: React.FC<SkillInputProps> = ({ onCompetenceSelect }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Competence[]>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [searchParam, setSearchParam] = useState<string>('')
  const selectedCompetences = useSelector(
    (state: ApplicationState) => state.competence.selectedCompetences
  )
  const { t } = useTranslation('translation', {
    keyPrefix: 'kompetenser',
  })
  const { isFetching, data = [] as Competence[] } = useGetCompetencesQuery(
    searchParam,
    {
      skip: !searchParam,
    }
  )

  useEffect(() => {
    if (!equals(data, options)) {
      setOptions(data)
    }
  }, [data, options])

  const reset = () => {
    setOptions([])
    setSearchParam('')
  }

  useDebounce(
    () => {
      if (searchInput) setSearchParam(searchInput.toLowerCase())
      else reset()
    },
    [searchInput],
    400
  )

  const { palette } = useTheme()
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: Competence) => option.term,
  })

  return (
    <Autocomplete
      filterOptions={filterOptions}
      fullWidth
      sx={{ backgroundColor: 'white' }}
      loadingText={'Hämtar...'}
      noOptionsText={t('noOpt')}
      inputValue={searchInput}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(_, newValue) => {
        const value = newValue as Competence
        onCompetenceSelect(value)
        setSearchInput('')
      }}
      isOptionEqualToValue={(option, value) => option.term === value.term}
      getOptionLabel={(option) => option.term}
      options={options}
      loading={isFetching}
      renderOption={(props, option) => {
        return (
          <li data-testid="skills-input-value" {...props} key={option.term}>
            <Stack>
              <Typography sx={{ color: palette.text.disabled }} variant="body2">
                {capitalize(option.term ?? '')}
              </Typography>
              <Typography variant="body1">
                {capitalize(option.term ?? '')}
              </Typography>
            </Stack>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            selectedCompetences.length > 0 ? t('addCompetence') : t('minTre')
          }
          type="search"
          onChange={(e) => {
            setSearchInput(e.target.value)
          }}
          inputProps={{
            ...params.inputProps,
            'data-testid': 'skills-input',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default SkillInput
