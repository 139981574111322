import { Box, Stack, Typography, useTheme } from '@mui/material'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import ArrowDownwardRounded from '@mui/icons-material/ArrowDownward'
import type { OccupationOutlook } from '../../types/OccupationOutlook.types'
import { useTranslation } from 'react-i18next'

interface PrognosCardProps {
  occupationOutlook: OccupationOutlook
}

const PrognosCard = ({ occupationOutlook }: PrognosCardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })

  const { palette } = useTheme()

  if (!occupationOutlook.prognos) return null

  return (
    <Box data-testid="prognosis-card">
      <Stack
        direction="row"
        gap={{ xs: 2, sm: 6 }}
        alignItems={'center'}
        alignContent={'space-evenly'}
        mb={2}
        height={'90px'}
      >
        <Box minWidth="68px" display="flex" justifyContent="center">
          {occupationOutlook.prognos === 'vara oförändrad' ? (
            <ArrowForwardRoundedIcon
              color="info"
              sx={{
                width: '50px',
                height: '50px',
                fill: palette.neutral?.light,
              }}
            />
          ) : occupationOutlook.prognos === 'öka' ? (
            <ArrowOutwardRoundedIcon
              color="secondary"
              sx={{ width: '50px', height: '50px' }}
            />
          ) : (
            <ArrowDownwardRounded
              color="error"
              sx={{ width: '50px', height: '50px' }}
            />
          )}
        </Box>
        <Stack flexGrow={1}>
          <Typography variant="h5" component="p">
            {occupationOutlook.prognos === 'vara oförändrad'
              ? t('oforandradEfterfragan')
              : occupationOutlook.prognos === 'öka'
                ? t('okandeEfterfragan')
                : t('minskandeEfterfragan')}
          </Typography>
          <Typography variant="body1">{t('femArsSikt')}</Typography>
        </Stack>
      </Stack>
      <Typography variant="body2" color="text.secondary" mb={1}>
        {occupationOutlook.textRekryteringssituation}
      </Typography>
    </Box>
  )
}

export default PrognosCard
