import { referenceDataApi as refApi } from '../referenceDataApi'
import { Data } from 'src/features/Rapporter/Filters/Filter/Filter'
import {
  OccupationOutlook,
  OccupationOutlookNew,
} from 'src/features/Yrke/types/OccupationOutlook.types'
import { County } from 'src/types/County'
import { SalaryStatistics } from 'src/types/SalaryStatistics.types'
import { getOutlookValue } from 'src/utils/helpers/getOutlookValue'

export const referenceDataApi = refApi.injectEndpoints({
  endpoints: (build) => ({
    getOccupationOutlooks: build.query<
      OccupationOutlook | null,
      {
        ssyk: string
        occupationId: string
        lan: string
      }
    >({
      query: ({ ssyk, occupationId, lan }) => ({
        url: `/occupationalbarometer`,
        params: {
          ssyk,
          occupationId,
          lan,
        },
      }),
      transformResponse: (
        response: OccupationOutlookNew[] | string[],
        _,
        { ssyk, occupationId }
      ): OccupationOutlook | null => {
        // TODO: this is a hack, the backend should return a error code instead of an empty array, then we can skip this check and the | null above
        if (!response || response.length === 0) return null

        const occupationOutlook = response[0] as OccupationOutlookNew

        // TODO: Phase out this and go from OccupationOutlookNew into OccupationOutlook
        return {
          id: occupationOutlook.id,
          occupationId,
          conceptId: occupationOutlook.conceptId,
          year: occupationOutlook.omgang.slice(0, 4) ?? '',
          ssyk,
          outlookLabel: occupationOutlook?.jobbmojligheter
            ? `${occupationOutlook?.jobbmojligheter?.charAt(0).toUpperCase() + occupationOutlook?.jobbmojligheter?.slice(1)} möjligheter`
            : '',
          outlookValue: getOutlookValue(occupationOutlook.jobbmojligheter),
          geography: 'Riket',
          ingress: occupationOutlook.textJobbmojligheter,
          prognos: occupationOutlook.prognos,
          textRekryteringssituation:
            occupationOutlook.textRekryteringssituation,
        }
      },
    }),
    getSalaryStatistics: build.query<SalaryStatistics, string>({
      query: (ssyk) => ({
        url: `/salarystatistics?ssyk=${ssyk}`,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getAllCounties: build.query<Data[], void>({
      query: () => `locations/counties`,
      transformResponse: (response: County[]): Data[] => {
        return response.map((el) => ({
          id: el.countyCode.startsWith('0')
            ? el.countyCode.slice(1)
            : el.countyCode,
          label: el.county,
        }))
      },
    }),
  }),
})

export const {
  useGetOccupationOutlooksQuery,
  useGetSalaryStatisticsQuery,
  useGetAllCountiesQuery,
} = referenceDataApi
