import {
  Typography,
  useTheme,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid2,
  alpha,
} from '@mui/material'
import React, { useState } from 'react'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'

import YrkeSkeleton from './components/YrkeSkeleton/YrkeSkeleton'
import Outlook from './components/Outlook/Outlook'
import SourceMetaText from './components/SourceMetaText/SourceMetaText'
import { OccupationStatistics } from './types/OccupationStatistics.types'
import { Occupation } from './types/Occupation.types'
import ScbStatistics from './components/ScbStatistics/ScbStatistics'
import { AutoStories, ExpandMore } from '@mui/icons-material'
import IndexCard from './components/IndexCard/IndexCard'
import AFLogo from 'src/components/Icons/AFLogo'
import ScbLogo from 'src/components/Icons/ScbLogo'
import { BackToTop } from 'src/components/BackToTop/BackToTop'
import { usePostMatchByOccupationIdQuery } from 'src/api/educations'
import {
  useGetOccupationQuery,
  useGetOccupationStatisticsQuery,
} from 'src/api/occupation'
import {
  useGetOccupationAgeStatisticsQuery,
  useGetOccupationEducationStatisticsQuery,
  useGetOccupationSectorStatisticsQuery,
  useGetSelfEmployedStatisticsQuery,
  useGetSectorSalaryStatisticsQuery,
} from 'src/api/statistics'
import {
  useGetOccupationOutlooksQuery,
  useGetSalaryStatisticsQuery,
} from 'src/api/referenceData'
import { OccupationOutlook } from './types/OccupationOutlook.types'
import EmptyResult from 'src/components/EmptyResult/EmptyResult'
import PageWrapper from 'src/components/PageWrapper/PageWrapper'
import WysiwygEditor from 'src/components/WysiwygEditor'
import { Competence } from 'src/types/Competence'
import { SalaryStatistics } from 'src/types/SalaryStatistics.types'
import EducationsList from '../Utbildning/components/EducationsList/EducationsList'
import { useTranslation } from 'react-i18next'
import { Content } from 'src/App/App.types'
import { useSelectedId } from 'src/utils/hooks/useSelectedId'
import useDeviceSize from 'src/utils/hooks/useDeviceSize'
import { COUNTRY_WIDE_CODE } from 'src/utils/constants/constants'

const Yrke: React.FC = () => {
  const id = useSelectedId()

  const { palette } = useTheme()
  const { smallScreensUp } = useDeviceSize()
  const [showAllSkills, setShowAllSkills] = useState(false)

  const { t: tYrke } = useTranslation('translation', { keyPrefix: 'yrke' })
  const { t: tKallor } = useTranslation('translation', { keyPrefix: 'kallor' })

  const { yrke } = useEpiContent<Content>()

  const customDictionary = useEpiCustomDictionary(['yrke'])

  const {
    data = {} as Occupation,
    isFetching: isFetchingOccupation,
    isError: isErrorFetchingOccupation,
  } = useGetOccupationQuery(id)

  const {
    data: educationsData,
    isFetching: isFetchingEducations,
    isError: isErrorFetchingEducations,
  } = usePostMatchByOccupationIdQuery(id)

  const {
    data: occupationalOutlook = {} as OccupationOutlook,
    isFetching: isFetchingOutlooks,
  } = useGetOccupationOutlooksQuery(
    {
      ssyk: data.occupationGroup?.ssyk ?? '',
      occupationId: data.id,
      lan: COUNTRY_WIDE_CODE,
    },
    {
      skip: !data.occupationGroup?.ssyk,
    }
  )

  const {
    data: salaryStatistics = {} as SalaryStatistics,
    isFetching: isFetchingSalaryStatistics,
  } = useGetSalaryStatisticsQuery(data.occupationGroup?.ssyk ?? '', {
    skip: !data.occupationGroup?.ssyk,
  })

  const {
    data: occupationStatistics,
    isFetching: isFetchingOccupationStatistics,
  } = useGetOccupationStatisticsQuery(data.occupationGroup?.ssyk ?? '', {
    skip: !data.occupationGroup?.ssyk,
  })

  const { data: occupationAgeStatistics } = useGetOccupationAgeStatisticsQuery(
    data.occupationGroup?.ssyk ?? '',
    { skip: !data.occupationGroup?.ssyk }
  )
  const { data: occupationEducationStatistics } =
    useGetOccupationEducationStatisticsQuery(data.occupationGroup?.ssyk ?? '', {
      skip: !data.occupationGroup?.ssyk,
    })

  const { data: occupationSectorStatistics } =
    useGetOccupationSectorStatisticsQuery(data.occupationGroup?.ssyk ?? '', {
      skip: !data.occupationGroup?.ssyk,
    })

  const { data: selfEmployedStatistics } = useGetSelfEmployedStatisticsQuery(
    data.occupationGroup?.ssyk ?? '',
    {
      skip: !data.occupationGroup?.ssyk,
    }
  )

  const { data: sectorSalaryStatistics } = useGetSectorSalaryStatisticsQuery(
    data.occupationGroup?.ssyk ?? '',
    {
      skip: !data.occupationGroup?.ssyk,
    }
  )

  const salaryIngress = (
    salaryStatistics: SalaryStatistics,
    occupationLabel: string,
    occupationGroupLabel: string
  ) =>
    customDictionary('salaryDescription', {
      minTio: salaryStatistics?.percentile10?.toLocaleString(),
      maxTio: salaryStatistics?.percentile90?.toLocaleString(),
      ar: salaryStatistics?.year?.toString(),
      yrke: occupationLabel,
      yrkesgrupp: occupationGroupLabel,
      medel: salaryStatistics?.average?.toLocaleString(),
    })

  const occupationStatisticsIngress = (
    occupationStatistics: OccupationStatistics,
    occupationGroupLabel: string,
    occupationLabel: string
  ) =>
    customDictionary('occupationStatisticsIngress', {
      total: (
        occupationStatistics.men + occupationStatistics.women
      ).toLocaleString(),
      men: String(
        Math.round(
          (occupationStatistics.men /
            (occupationStatistics.men + occupationStatistics.women)) *
            100
        )
      ),
      women: String(
        Math.round(
          (occupationStatistics.women /
            (occupationStatistics.men + occupationStatistics.women)) *
            100
        )
      ),
      ar: occupationStatistics.year?.toString(),
      yrkesgrupp: occupationGroupLabel,
      yrke: occupationLabel,
    })

  const filteredSkills =
    data?.competences?.filter(
      (c) => c.percentForOccupation && c.percentForOccupation > 0.06
    ) ?? []
  const skills = showAllSkills ? filteredSkills : filteredSkills.slice(0, 15)
  const showAllSkillsButton = filteredSkills.length > 15

  const skillChunks: Competence[][] = skills.reduce<Competence[][]>(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 15)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] as Competence[]
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    },
    []
  )

  const showAFStatistics = occupationalOutlook?.outlookValue

  const showScbStatistics =
    salaryStatistics ||
    occupationStatistics ||
    occupationAgeStatistics ||
    occupationEducationStatistics ||
    occupationSectorStatistics ||
    selfEmployedStatistics ||
    sectorSalaryStatistics
  const borderColor = alpha(palette.neutral?.main ?? 'rgb()', 0.16)

  if (
    isFetchingOccupation ||
    isFetchingOutlooks ||
    isFetchingSalaryStatistics ||
    isFetchingOccupationStatistics
  )
    return <YrkeSkeleton />

  return (
    <>
      <PageWrapper
        isLoading={isFetchingOccupation}
        errorText={tYrke('errorFetchYrke')}
        errorLoadingData={isErrorFetchingOccupation}
      >
        <Typography
          data-testid="occupation-header"
          variant="h1"
          mb={1}
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {data.occupationLabel}
        </Typography>
        <Typography gutterBottom variant="body1" mb={4}>
          {data.description}
        </Typography>
        <Typography
          variant="h2"
          mb={1}
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {yrke.header}
        </Typography>
        <Typography gutterBottom variant="body1" mb={4}>
          {yrke.ingress} {data.occupationLabel}.
        </Typography>

        {smallScreensUp && (
          <Grid2
            container
            direction="row"
            spacing={3}
            alignItems="stretch"
            marginBottom={5}
          >
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} alignItems="flex-start">
              <IndexCard
                disabled={!showAFStatistics}
                header={yrke.afHeader}
                preamble={yrke.afPreamble}
                link={tYrke('afLink')}
                icon={<AFLogo />}
                missing={tYrke('afMissing')}
                section="afStatistik-header"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
              <IndexCard
                disabled={!showScbStatistics}
                header={yrke.scbHeader}
                preamble={yrke.scbPreamble}
                link={tYrke('scbLink')}
                icon={<ScbLogo />}
                missing={tYrke('scbMissing')}
                section="scbStatistik-header"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
              <IndexCard
                disabled={skills.length === 0}
                header={yrke.kompetenserHeader}
                preamble={yrke.skillsPreamble}
                link={tYrke('skillsLink')}
                icon={<AFLogo />}
                missing={tYrke('skillsMissing')}
                section="skills-header"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
              <IndexCard
                disabled={!educationsData}
                header={yrke.educationHeader}
                preamble={yrke.educationPreamble}
                link={tYrke('educationLink')}
                icon={<AutoStories width="32px" sx={{ fontSize: '32px' }} />}
                missing={tYrke('educationMissing')}
                section="education-header"
                background="white"
              />
            </Grid2>
          </Grid2>
        )}
        {showAFStatistics && (
          <Accordion
            disableGutters
            defaultExpanded={smallScreensUp}
            elevation={0}
            sx={{
              '&:before': { height: '0px' },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    color: palette.text.primary,
                    fontSize: { xs: '28px', sm: '38px', md: '48px' },
                  }}
                />
              }
              sx={{
                p: 0,
                m: 0,
                '.MuiAccordionSummary-content': {
                  marginTop: { xs: 0, sm: 3 },
                  marginBottom: { xs: 0, sm: 3 },
                  backgroundColor: {
                    xs: palette.surface?.blue,
                    sm: 'transparent',
                  },
                  borderRadius: { xs: 2, sm: 0 },
                },
                '.Mui-expanded': {
                  borderRadius: '8px 8px 0 0',
                },
                '.MuiAccordionSummary-expandIconWrapper': {
                  position: { xs: 'absolute', sm: 'static' },
                  top: '16px',
                  right: '16px',
                },
              }}
              id="afStatistik-header"
              aria-controls="afStatistik-content"
            >
              {smallScreensUp && (
                <Typography variant="h2" mb={0} component="h2">
                  {yrke.afHeader}
                </Typography>
              )}
              {!smallScreensUp && (
                <IndexCard
                  disabled={!showAFStatistics}
                  header={yrke.afHeader}
                  preamble={yrke.afPreamble}
                  link={tYrke('afLink')}
                  icon={<AFLogo />}
                  missing={tYrke('afMissing')}
                  section="afStatistik-header"
                />
              )}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 0,
                m: 0,
                mb: { xs: 0, sm: '64px' },
              }}
            >
              <Outlook occupationOutlook={occupationalOutlook} />
            </AccordionDetails>
          </Accordion>
        )}
        {!showAFStatistics && !smallScreensUp && (
          <IndexCard
            disabled={!showAFStatistics}
            header={yrke.afHeader}
            preamble={yrke.afPreamble}
            link={tYrke('afLink')}
            icon={<AFLogo />}
            missing={tYrke('afMissing')}
            section="afStatistik-header"
          />
        )}
        {showScbStatistics && (
          <Accordion
            disableGutters
            defaultExpanded={smallScreensUp}
            elevation={0}
            sx={{
              '&:before': { height: '0px' },
              mt: { xs: 3, sm: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    color: palette.text.primary,
                    fontSize: { xs: '28px', sm: '38px', md: '48px' },
                  }}
                />
              }
              sx={{
                p: 0,
                m: 0,
                '.MuiAccordionSummary-content': {
                  marginTop: { xs: 0, sm: 3 },
                  marginBottom: { xs: 0, sm: 3 },
                  backgroundColor: {
                    xs: palette.surface?.blue,
                    sm: 'transparent',
                  },
                  borderRadius: { xs: 2, sm: 0 },
                },
                '.Mui-expanded': {
                  borderRadius: '8px 8px 0 0',
                },
                '.MuiAccordionSummary-expandIconWrapper': {
                  position: { xs: 'absolute', sm: 'static' },
                  top: '16px',
                  right: '16px',
                },
              }}
              id="scbStatistik-header"
              aria-controls="scbStatistik-content"
            >
              {smallScreensUp && (
                <Typography variant="h2" mb={0}>
                  {yrke.scbHeader}
                </Typography>
              )}
              {!smallScreensUp && (
                <IndexCard
                  disabled={!showScbStatistics}
                  header={yrke.scbHeader}
                  preamble={yrke.scbPreamble}
                  link={tYrke('scbLink')}
                  icon={<ScbLogo />}
                  missing={tYrke('scbMissing')}
                  section="scbStatistik-header"
                />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, m: 0, mb: { xs: 0, sm: '64px' } }}>
              <ScbStatistics
                salaryIngress={salaryIngress(
                  salaryStatistics,
                  data.occupationLabel,
                  data.occupationGroup?.occupationGroupLabel || ''
                )}
                salaryStatistics={salaryStatistics}
                occupationStatisticsIngress={
                  occupationStatistics
                    ? occupationStatisticsIngress(
                        occupationStatistics,
                        data.occupationGroup?.occupationGroupLabel || '',
                        data.occupationLabel
                      )
                    : ''
                }
                occupationStatistics={occupationStatistics}
                occupationAgeStatistics={occupationAgeStatistics}
                occupationEducationStatistics={occupationEducationStatistics}
                occupationSectorStatistics={occupationSectorStatistics}
                selfEmployedStatistics={selfEmployedStatistics}
                sectorSalaryStatistics={sectorSalaryStatistics}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {!showScbStatistics && !smallScreensUp && (
          <IndexCard
            disabled={!showScbStatistics}
            header={yrke.scbHeader}
            preamble={yrke.scbPreamble}
            link={tYrke('scbLink')}
            icon={<ScbLogo />}
            missing={tYrke('scbMissing')}
            section="scbStatistik-header"
          />
        )}
        {skills.length > 0 && (
          <Accordion
            disableGutters
            defaultExpanded={smallScreensUp}
            elevation={0}
            sx={{
              '&:before': { height: '0px' },
              mt: { xs: 3, sm: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    color: palette.text.primary,
                    fontSize: { xs: '28px', sm: '38px', md: '48px' },
                  }}
                />
              }
              sx={{
                p: 0,
                m: 0,
                '.MuiAccordionSummary-content': {
                  marginTop: { xs: 0, sm: 3 },
                  marginBottom: { xs: 0, sm: 3 },
                  backgroundColor: {
                    xs: palette.surface?.blue,
                    sm: 'transparent',
                  },
                  borderRadius: { xs: 2, sm: 0 },
                },
                '.Mui-expanded': {
                  borderRadius: '8px 8px 0 0',
                },
                '.MuiAccordionSummary-expandIconWrapper': {
                  position: { xs: 'absolute', sm: 'static' },
                  top: '16px',
                  right: '16px',
                },
              }}
              id="skills-header"
              aria-controls="skills-content"
            >
              {smallScreensUp && (
                <Typography mb={0} mt={0} variant="h2">
                  {yrke.skillsHeading}
                </Typography>
              )}
              {!smallScreensUp && (
                <IndexCard
                  disabled={skills.length === 0}
                  header={yrke.skillsHeading}
                  preamble={yrke.skillsPreamble}
                  link={tYrke('skillsLink')}
                  icon={<AFLogo />}
                  missing={tYrke('skillsMissing')}
                  section="skills-header"
                />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, m: 0, mb: { xs: 0, sm: '64px' } }}>
              {smallScreensUp && (
                <WysiwygEditor
                  styles={{ fontSize: '16px', mb: 3 }}
                  htmlFormattedString={customDictionary('kompetenslista', {
                    noOfSkills: filteredSkills.length.toString(),
                    yrkesgrupp:
                      data.occupationGroup?.occupationGroupLabel ?? '',
                    yrke: data.occupationLabel,
                  })}
                />
              )}
              <Card
                sx={{
                  backgroundColor: palette.surface?.blue,
                  border: 'none',
                  borderTop: {
                    xs: `1px solid ${borderColor}`,
                    sm: 'none',
                  },
                  borderRadius: { xs: '0 0 8px 8px', sm: 2 },
                }}
              >
                <CardContent sx={{ p: { xs: 3, sm: 5 } }}>
                  {skillChunks?.map((ch, i) => (
                    <List
                      key={i}
                      component="ol"
                      sx={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        padding: '0',
                      }}
                    >
                      {ch?.map((s, j) => (
                        <ListItem
                          data-testid="skill-list-item"
                          key={s.term}
                          sx={{
                            paddingBottom: {
                              md: 2,
                              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              xs: `${j % 5 === 4 ? 24 : 8}px`,
                            },
                            width: { md: '33%' },
                            border: 'none',
                            paddingLeft: '0',
                            color: palette.info.dark,
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                backgroundColor: palette.info.light,
                                color: palette.info.dark,
                              }}
                            >
                              <Typography variant="subtitle1" component="span">
                                {' '}
                                {i * 15 + j + 1}
                              </Typography>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText> {s.term}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  ))}

                  <br />
                  {showAllSkillsButton && (
                    <Button
                      data-testid="show-all-skills-button"
                      size="medium"
                      color="primary"
                      variant="outlined"
                      sx={{ mb: 1, width: { xs: '100%', sm: 'auto' } }}
                      onClick={() => {
                        setShowAllSkills(!showAllSkills)
                      }}
                    >
                      {showAllSkills ? tYrke('visaMindre') : tYrke('visaMer')}
                    </Button>
                  )}
                  {!smallScreensUp && (
                    <SourceMetaText text={tKallor('kallaAF')} />
                  )}
                </CardContent>
              </Card>
              {smallScreensUp && <SourceMetaText text={tKallor('kallaAF')} />}
            </AccordionDetails>
          </Accordion>
        )}
        {skills.length === 0 && !smallScreensUp && (
          <IndexCard
            disabled={skills.length === 0}
            header={yrke.kompetenserHeader}
            preamble={yrke.skillsPreamble}
            link={tYrke('skillsLink')}
            icon={<AFLogo />}
            missing={tYrke('skillsMissing')}
            section="skills-header"
          />
        )}
        {educationsData && (
          <>
            <Typography
              variant="h2"
              mb={-3}
              mt={{ xs: 2, sm: 0 }}
              id="education-header"
            >
              {yrke.educationHeader}
            </Typography>
            <PageWrapper
              isLoading={isFetchingEducations}
              errorText={tYrke('errorFetchUtb')}
              errorLoadingData={isErrorFetchingEducations}
            >
              <EducationsList
                itemHeaderComponent="h2"
                showTypeToggle={false}
                educations={educationsData.educations ?? []}
                noResult={
                  <EmptyResult
                    heading={tYrke('tomtResultat')}
                    description={tYrke('tomtResultatUtb')}
                  />
                }
              />
            </PageWrapper>
          </>
        )}
        <BackToTop
          text={tYrke('tillbakaUpp')}
          ariaLabel={tYrke('tillbakaUpp')}
        />
      </PageWrapper>
    </>
  )
}

export default Yrke
