import { Button, Stack } from '@mui/material'

import Filter from './Filter'
import { FilterType } from './Filter/Filter'
import MobileFilter from './MobileFilter'
import { FilterBarDrawer } from './MobileFilter/FilterBarDrawer'
import { useTranslation } from 'react-i18next'
import useDeviceSize from 'src/utils/hooks/useDeviceSize'

const Filters = ({
  filters,
  handleSetFilters,
  handleResetFilters,
}: {
  filters: FilterType[]
  handleSetFilters: (filterId: string, value: string) => void
  handleResetFilters: (filterId?: string) => void
}) => {
  const { mediumScreensUp } = useDeviceSize()
  const { t } = useTranslation('translation', {
    keyPrefix: 'filtrering',
  })

  return mediumScreensUp ? (
    <Stack
      direction={'row'}
      spacing={1}
      sx={{ marginTop: 2, paddingBottom: 4 }}
    >
      {filters.map((el) => (
        <Filter
          key={el.id}
          filter={el}
          handleSetFilters={handleSetFilters}
          handleResetFilters={handleResetFilters}
        />
      ))}
    </Stack>
  ) : (
    <>
      <FilterBarDrawer
        title={t('allFilters')}
        activeFilterCount={filters.reduce(
          (acc, init) => acc + init.filters.length,
          0
        )}
      >
        <Button
          data-testid="reset-all-filters-button"
          size="small"
          variant="text"
          sx={{ p: 2 }}
          onClick={() => {
            handleResetFilters()
          }}
          disabled={filters.every((el) => el.filters.length === 0)}
        >
          {t('clearAllFilters')}
        </Button>
        {filters.map((el) => (
          <MobileFilter
            key={el.id}
            filter={el}
            handleSetFilters={handleSetFilters}
          />
        ))}
      </FilterBarDrawer>
    </>
  )
}

export default Filters
