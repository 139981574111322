import { DownloadForOffline } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  useTheme,
  Link,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'

import { format, parseISO } from 'date-fns'

import { IndustryArticle } from './types/IndustryArticle.type'
import downloadBlobHelper from 'src/utils/helpers/downloadBlobHelper'
import useDeviceSize from 'src/utils/hooks/useDeviceSize'
import { useTranslation } from 'react-i18next'
import { useEpiPagePath } from '@trr/app-shell-data'

interface ArticleCardProps {
  values?: IndustryArticle
}

const ArticleCard = ({ values }: ArticleCardProps) => {
  const pagePath = useEpiPagePath()
  const { t } = useTranslation('translation', {
    keyPrefix: 'rapport',
  })
  const { palette } = useTheme()
  const { mediumScreensUp } = useDeviceSize()
  const publicationDate = values?.publicationDate
    ? format(parseISO(values?.publicationDate as string), 'yyyy-MM-dd')
    : ''
  const type = 'Rapport'
  const handleClickDownload = async () => {
    if (values?.id)
      await downloadBlobHelper(values?.id, values?.fileName ?? values.title)
  }

  const ariaLabel = `${t('link')} ${values?.title ?? ''}`
  const ariaLabel2 = `${t('laddaNer')} ${values?.title ?? ''}`
  return (
    <Card
      data-testid="article-card"
      component="article"
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Box>
        <CardContent sx={{ p: 3 }}>
          <Typography
            color={palette.text.secondary}
            variant="subtitle2"
            component="p"
          >
            {type} {t('fran')} {values?.publisher} | {publicationDate}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {values?.title}
          </Typography>
          <Typography variant="body2">{values?.description}</Typography>
        </CardContent>
        <CardActions sx={{ p: '12px' }}>
          <Button
            component={Link}
            href={`${pagePath}rapport/${values?.id ?? ''}`}
            variant="text"
            size="small"
            aria-label={ariaLabel}
          >
            {t('link')}
          </Button>
          <Button
            startIcon={<DownloadForOffline />}
            variant="text"
            size="small"
            onClick={() => void handleClickDownload()}
            aria-label={ariaLabel2}
          >
            {t('laddaNer')}
          </Button>
        </CardActions>
      </Box>
      {mediumScreensUp && (
        <Box
          sx={{
            p: 5,
            backgroundColor: 'surface.purple',
            flexDirection: 'column',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <DescriptionIcon color="primary" sx={{ fontSize: '96px' }} />
        </Box>
      )}
    </Card>
  )
}

export default ArticleCard
