import { Box, Stack } from '@mui/material'
import WysiwygEditor from 'src/components/WysiwygEditor'
import { useEpiContent } from '@trr/app-shell-data'
import EpiImage from 'src/components/EpiImage/EpiImage'
import useDeviceSize from 'src/utils/hooks/useDeviceSize'

interface KompetenserContent {
  [key: string]: unknown
  kompetenser: {
    puffText: {
      mainBody: string
    }
    puffBild: {
      url: string
    }
  }
}

const KompetenserStart = () => {
  const {
    kompetenser: { puffText, puffBild },
  } = useEpiContent<KompetenserContent>()
  const { mediumScreensUp } = useDeviceSize()

  return (
    <Stack
      data-testid="kompetenser-start"
      direction="row"
      sx={{ mt: 4, backgroundColor: 'surface.orange' }}
    >
      <Box sx={{ padding: { sm: 5, xs: 2 }, paddingTop: { sm: 0 } }}>
        {puffText?.mainBody && (
          <WysiwygEditor
            htmlFormattedString={puffText?.mainBody}
            styles={{
              fontSize: '16px',
              pt: 1,
              pb: 3,
            }}
          />
        )}
      </Box>
      {mediumScreensUp && puffBild && (
        <EpiImage width={'400px'} url={puffBild.url} />
      )}
    </Stack>
  )
}

export default KompetenserStart
