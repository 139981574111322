export const truncateText = (
  text?: string,
  maxLength?: number,
  ending?: string
): string => {
  if (text && maxLength && text.length > maxLength) {
    // Trim the text to the maximum length, without breaking a sentence
    text = text.substring(0, maxLength)
    const lastPeriod = text.lastIndexOf('.')
    if (lastPeriod !== -1) {
      text = text.substring(0, lastPeriod + 1)
    }
    // Add the ending
    if (ending) text += ending
  }
  return text ?? ''
}
