import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { translation } from 'src/content/resources'

export const initTranslations = () => {
  void i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        sv: {
          translation,
        },
      },
      ns: 'translation',
      lng: 'sv',
      fallbackLng: 'sv',
      defaultNS: 'translation',
      fallbackNS: 'translation',
    })
}
