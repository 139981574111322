import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'

import { Content } from 'src/App/App.types'
import { useEpiContent } from '@trr/app-shell-data'

import EpiImage from '../EpiImage/EpiImage'
import useDeviceSize from 'src/utils/hooks/useDeviceSize'
import { useTranslation } from 'react-i18next'

const ReliabilityBlock = () => {
  const { mediumScreensUp } = useDeviceSize()
  const { palette } = useTheme()
  const { t } = useTranslation('translation', {
    keyPrefix: 'palitlighetsblock',
  })

  const {
    utbildningar: {
      palitlighetsblock: {
        header,
        paragraph1,
        paragraph2,
        AFlink,
        SCBlink,
        illustrationsamarbeteUrl,
      },
    },
  } = useEpiContent<Content>()

  if (!header) return null

  return (
    <Box
      sx={{
        mt: 4,
        padding: { sm: 5, xs: 2 },
        backgroundColor: palette.surface?.purple,
      }}
    >
      <Stack sx={{ flexDirection: { md: 'row' } }}>
        <Box
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          {header && (
            <Typography gutterBottom variant="h3" component="h2">
              {header}
            </Typography>
          )}
          {paragraph1 && (
            <Typography gutterBottom variant="body1">
              {paragraph1}
            </Typography>
          )}
          {paragraph2 && (
            <Typography gutterBottom variant="body1">
              {paragraph2}
            </Typography>
          )}
          <Stack
            sx={{
              flexDirection: { sm: 'row' },
              flexFlow: 'wrap',
              mb: 2,
              mt: 2,
            }}
            gap={2}
          >
            <Button
              key={AFlink}
              component={'a'}
              target="_blank"
              startIcon={<OpenInNew />}
              href={AFlink}
              variant="outlined"
              aria-label={t('AFLinkText')}
            >
              {t('AFLinkText')}
            </Button>
            <Button
              key={SCBlink}
              component={'a'}
              target="_blank"
              startIcon={<OpenInNew />}
              href={SCBlink}
              variant="outlined"
              aria-label={t('SCBLinkText')}
            >
              {t('SCBLinkText')}
            </Button>
          </Stack>
        </Box>
        {illustrationsamarbeteUrl && mediumScreensUp && (
          <EpiImage
            width={'400px'}
            height={'346px'}
            url={illustrationsamarbeteUrl}
          />
        )}
      </Stack>
    </Box>
  )
}

export default ReliabilityBlock
