import React, { useEffect, useState } from 'react'
import {
  Stack,
  Box,
  Typography,
  useTheme,
  capitalize,
  type SelectChangeEvent,
  alpha,
} from '@mui/material'
import { OccupationStatistics as OccupationStatisticsType } from 'src/features/Yrke/types/OccupationStatistics.types'
import ChipSelect, { MenuItem } from 'src/components/ChipSelect/ChipSelect'
import { useGetOccupationGenderStatisticsQuery } from 'src/api/statistics'
import OccupationBarChart from '../../OccupationBarChart/OccupationBarChart'
import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import { useTranslation } from 'react-i18next'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'src/App/App.types'

interface OccupationStatisticsProps {
  occupationStatisticsIngress: string
  occupationStatistics: OccupationStatisticsType
}

const OccupationStatistics: React.FC<OccupationStatisticsProps> = ({
  occupationStatisticsIngress,
  occupationStatistics,
}) => {
  const theme = useTheme()
  const { palette } = theme
  const [value, setValue] = useState<string | null>('010')
  const [open, setOpen] = useState(false)
  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''
  const { yrke } = useEpiContent<Content>()
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })

  const { data: occupationGenderStatistics, isLoading } =
    useGetOccupationGenderStatisticsQuery(occupationStatistics.ssyk ?? '', {
      skip: !occupationStatistics.ssyk,
    })

  const [
    filteredOccupationGenderStatistics,
    setFilteredOccupationGenderStatistics,
  ] = useState<OccupationStatisticsType>({
    id: '',
    year: '',
    ssyk: '',
    men: 0,
    women: 0,
  })

  const onSelectSector = (event: SelectChangeEvent) => {
    setValue(event.target.value)
  }

  const handleOnClick = () => {
    setOpen(open ? false : true)
  }

  useEffect(() => {
    const selectedSector = occupationGenderStatistics?.sectors.find(
      (el) => el.sector === value
    )

    const transformed = {
      ...occupationGenderStatistics,
      men: selectedSector?.men,
      women: selectedSector?.women,
    } as OccupationStatisticsType

    setFilteredOccupationGenderStatistics(transformed)
  }, [occupationGenderStatistics, value])

  const menuItems = occupationGenderStatistics?.sectors.map((sector) => ({
    name: capitalize(sector.sectorName ?? ''),
    value: sector.sector,
  })) as MenuItem[]

  if (!occupationGenderStatistics || isLoading) return null

  return (
    <Stack
      sx={{
        mt: { sm: 3 },
        backgroundColor: palette.surface?.blue,
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: 0, sm: 2 }}
    >
      <Box sx={{ padding: { xs: 3, sm: 5 } }}>
        <Typography gutterBottom variant="h4" component="h3">
          {yrke.nulageHeader}
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          id="occupation-barchart-caption"
        >
          {occupationStatisticsIngress}
        </Typography>

        <ChipSelect
          value={value}
          label={capitalize(
            occupationGenderStatistics?.sectors.find(
              (sector) => sector.sector === value
            )?.sectorName ?? ''
          )}
          open={open}
          menuItems={menuItems}
          onChange={onSelectSector}
          onClick={handleOnClick}
        />

        <OccupationBarChart outlook={filteredOccupationGenderStatistics} />
        <SourceMetaText
          text={`${tKallor('kallaSCB')} (${filteredOccupationGenderStatistics?.year})`}
        />
      </Box>
    </Stack>
  )
}

export default OccupationStatistics
