import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useFormik } from 'formik'

import { useDispatch, useSelector } from 'react-redux'

import { mapToDetailedFilterObject, other, studyPaces } from './helpers'
import {
  useGetEducationFormsQuery,
  useGetEducationTypesQuery,
} from 'src/api/educationParameters'
import { ApplicationState } from 'src/store'
import {
  EducationFilters,
  updateFilters,
} from 'src/api/slices/educationFilterSlice'
import { useTranslation } from 'react-i18next'

interface EducationFilterModalProps {
  open: boolean
  onCloseClick: () => void
}

const EducationFilterModal = ({
  open,
  onCloseClick,
}: EducationFilterModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'utbildningar' })
  const { data: educationFormData, isLoading: isLoadingEducationFormData } =
    useGetEducationFormsQuery()
  const { data: educationTypeData, isLoading: isloadingEducationTypeData } =
    useGetEducationTypesQuery()
  const isLoading: boolean =
    isLoadingEducationFormData || isloadingEducationTypeData

  const filters = useSelector<ApplicationState, EducationFilters>(
    (state) => state.educationFilter.filters
  )
  const initialValues = {
    other: filters.other?.map((f) => f.id),
    educationForms: filters.educationForms?.map((f) => f.id),
    educationTypes: filters.educationTypes?.map((f) => f.id),
    studyPace: filters.studyPace?.id,
  }

  const dispatch = useDispatch()
  const { handleSubmit, handleChange, values, resetForm } = useFormik({
    initialValues: initialValues,
    onReset: () => {
      dispatch(
        updateFilters(
          mapToDetailedFilterObject(
            initialValues,
            educationFormData,
            educationTypeData
          )
        )
      )
    },
    onSubmit: (values) => {
      dispatch(
        updateFilters(
          mapToDetailedFilterObject(
            values,
            educationFormData,
            educationTypeData
          )
        )
      )
    },
  })

  if (isLoading) return null

  return (
    <Dialog
      data-testid="education-filter-modal"
      open={open}
      onClose={onCloseClick}
      scroll="paper"
      fullWidth
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>{t('filter')}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          {educationFormData && (
            <>
              <Typography variant="subtitle1" component="h3">
                {t('form')}
              </Typography>
              <FormGroup>
                {educationFormData.map((e) => (
                  <FormControlLabel
                    key={e.id}
                    name="educationForms"
                    value={e.id}
                    control={
                      <Checkbox
                        checked={values.educationForms?.includes(e.id)}
                        inputProps={{
                          // @ts-expect-error data-testid is not a valid prop, but it works and is used for testing
                          'data-testid': 'education-filter-checkbox',
                        }}
                      />
                    }
                    label={e.name}
                    onChange={handleChange}
                  />
                ))}
              </FormGroup>
            </>
          )}
          <Divider sx={{ marginBottom: 2, marginTop: 3 }} />
          <Typography variant="subtitle1" component="h3">
            {t('visaEndastUtbildningar')}
          </Typography>
          <FormGroup>
            {other.map((o) => (
              <FormControlLabel
                key={o.id}
                name="other"
                value={o.id}
                control={<Checkbox checked={values.other?.includes(o.id)} />}
                label={o.name}
                onChange={handleChange}
              />
            ))}
          </FormGroup>
          <Divider sx={{ marginBottom: 2, marginTop: 3 }} />
          <Typography variant="subtitle1" component="h3">
            {t('takt')}
          </Typography>
          <RadioGroup name="studyPace">
            {studyPaces.map((p) => (
              <FormControlLabel
                key={p.id}
                value={p.id}
                name="studyPace"
                control={<Radio checked={values.studyPace?.includes(p.id)} />}
                label={p.name}
                onChange={handleChange}
              />
            ))}
          </RadioGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="cancel-button"
          onClick={() => {
            resetForm()
            onCloseClick()
          }}
          variant="text"
        >
          {t('avbryt')}
        </Button>
        <Button
          data-testid="done-button"
          onClick={() => {
            handleSubmit()
            onCloseClick()
          }}
        >
          {t('klar')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EducationFilterModal
