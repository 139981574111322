import { Avatar, styled } from '@mui/material'

export const FilterSelectionIndicator = styled(Avatar)({
  width: '1.5rem',
  height: '1.5rem',
  marginLeft: '1rem',
  backgroundColor: '#089188',
  color: '#fff !important',
  fontSize: '0.75rem',
})
