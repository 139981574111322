import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'

import { useEpiContent } from '@trr/app-shell-data'
import WysiwygEditor from 'src/components/WysiwygEditor'
import EpiImage from 'src/components/EpiImage/EpiImage'

interface UtbildningarContent {
  [key: string]: unknown
  utbildningar: {
    puffText: {
      mainBody: string
    }
    puffBild: {
      url: string
    }
  }
}

const UtbildningarStart = () => {
  const theme = useTheme()
  const {
    utbildningar: { puffText, puffBild },
  } = useEpiContent<UtbildningarContent>()

  return (
    <Stack
      data-testid="utbildningar-start"
      direction="row"
      sx={{ mt: 4, backgroundColor: 'surface.orange' }}
    >
      <Box sx={{ padding: { sm: 5, xs: 2 }, paddingTop: { sm: 0 } }}>
        {puffText?.mainBody && (
          <WysiwygEditor
            htmlFormattedString={puffText?.mainBody}
            styles={{
              fontSize: '16px',
              pt: 1,
              pb: 3,
            }}
          />
        )}
      </Box>
      {useMediaQuery(theme.breakpoints.up('md')) && puffBild && (
        <EpiImage width={'400px'} url={puffBild.url} />
      )}
    </Stack>
  )
}

export default UtbildningarStart
