import { alpha, Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import SalaryBarChart from '../../SalaryBarChart/SalaryBarChart'
import { SalaryStatistics as SalaryStatisticsType } from 'src/types/SalaryStatistics.types'

interface SalaryStatisticsProps {
  salaryStatistics: SalaryStatisticsType
  salaryIngress: string
}

const SalaryStatistics = ({
  salaryStatistics,
  salaryIngress,
}: SalaryStatisticsProps) => {
  const { palette } = useTheme()

  const { t: tYrke } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })

  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''

  if (!salaryIngress) return null

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 5 },
        mt: { xs: 0, sm: 0.5 },
        backgroundColor: palette.surface?.blue,
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: 0, sm: 2 }}
    >
      <Typography gutterBottom variant="h4" component="h3">
        {tYrke('lonelage')}
      </Typography>
      <Typography gutterBottom variant="body1" id="salary-barchart-caption">
        {salaryIngress}
      </Typography>
      <Typography gutterBottom marginTop={2} variant="body1">
        {tYrke('salaryInfo')}
      </Typography>
      <SalaryBarChart statistics={salaryStatistics} />
      <SourceMetaText
        text={`${tKallor('kallaSCB')} (${salaryStatistics.year.toString()}).`}
      />
    </Box>
  )
}

export default SalaryStatistics
