import { useMediaQuery, useTheme } from '@mui/material'

const useDeviceSize = () => {
  const { breakpoints } = useTheme()
  const smallScreensUp = useMediaQuery(breakpoints.up('sm'))
  const mediumScreenDown = useMediaQuery(breakpoints.down('md'))
  const mediumScreensUp = useMediaQuery(breakpoints.up('md'))
  return { smallScreensUp, mediumScreensUp, mediumScreenDown } as const
}

export default useDeviceSize
