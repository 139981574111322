import { Data } from 'src/features/Rapporter/Filters/Filter/Filter'
import { IndustryArticle } from 'src/features/Rapporter/types/IndustryArticle.type'
import { OccupationGroup } from 'src/types/OccupationGroup'
import kompetensApi from '../kompetensApi'

export const industryReportApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    getIndustryReports: build.query<
      IndustryArticle[],
      {
        searchQuery: string
        regions?: string[]
        statuses?: string[]
        occupationGroups?: string[]
      }
    >({
      query: (arg) => ({
        url: `/industryReport`,
        params: {
          searchQuery: arg.searchQuery,
          ...(arg.regions &&
            arg.regions.length > 0 && { regions: arg.regions }),
          ...(arg.occupationGroups &&
            arg.occupationGroups.length > 0 && {
              occupationGroups: arg.occupationGroups,
            }),
        },
      }),
      transformResponse: (response: IndustryArticle[]) => {
        return response.map((r) => {
          const publicationDate =
            r?.publicationDate && r.publicationDate !== '0001-01-01T00:00:00'
              ? r.publicationDate
              : undefined
          return { ...r, publicationDate }
        })
      },
    }),
    getIndustryReport: build.query<IndustryArticle, string>({
      query: (id) => ({ url: `/industryReport/${id}` }),
      transformResponse: (response: IndustryArticle) => {
        return {
          ...response,
          publicationDate:
            response?.publicationDate &&
            response.publicationDate !== '0001-01-01T00:00:00'
              ? response.publicationDate
              : undefined,
        }
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getOccupationGroups: build.query<Data[], void>({
      query: () => `/IndustryReport/occupationGroups`,
      transformResponse: (response: OccupationGroup[]): Data[] =>
        response.map((el) => ({
          id: el.id,
          label: el.occupationGroupName,
        })),
    }),
  }),
})

export const {
  useGetIndustryReportsQuery,
  useGetIndustryReportQuery,
  useGetOccupationGroupsQuery,
} = industryReportApi
