import { Stack } from '@mui/material'

import type {
  OccupationAgeStatistics as OccupationAgeStatisticsType,
  OccupationEducationStatistics as OccupationEducationStatisticsType,
  OccupationSectorStatistics as OccupationSectorStatisticsType,
  OccupationStatistics as OccupationStatisticsType,
  SectorSalaryStatistics as SectorSalaryStatisticsType,
  SelfEmployedStatistics as SelfEmployedStatisticsType,
} from '../../types/OccupationStatistics.types'
import { SalaryStatistics as SalaryStatisticsType } from 'src/types/SalaryStatistics.types'
import OccupationStatistics from './components/OccupationStatistics'
import SectorSalaryStatistics from './components/SectorSalaryStatistics'
import SelfEmployedStatistics from './components/SelfEmployedStatistics'
import OccupationEducationStatistics from './components/OccupationEducationStatistics'
import OccupationAgeStatistics from './components/OccupationAgeStatistics'
import OccupationSectorStatistics from './components/OccupationSectorStatistics'
import SalaryStatistics from './components/SalaryStatistics'

interface ScbParamsProps {
  salaryIngress: string
  occupationStatisticsIngress: string
  salaryStatistics: SalaryStatisticsType | undefined
  occupationStatistics: OccupationStatisticsType | undefined
  occupationAgeStatistics: OccupationAgeStatisticsType | undefined
  occupationEducationStatistics: OccupationEducationStatisticsType | undefined
  occupationSectorStatistics: OccupationSectorStatisticsType | undefined
  selfEmployedStatistics: SelfEmployedStatisticsType | undefined
  sectorSalaryStatistics: SectorSalaryStatisticsType | undefined
}

const ScbStatistics = ({
  salaryIngress,
  salaryStatistics,
  occupationStatisticsIngress,
  occupationStatistics,
  occupationAgeStatistics,
  occupationEducationStatistics,
  occupationSectorStatistics,
  selfEmployedStatistics,
  sectorSalaryStatistics,
}: ScbParamsProps) => {
  const horisontal = !(occupationAgeStatistics && occupationSectorStatistics)

  return (
    <>
      {/* Lönenivå */}
      {salaryStatistics && (
        <SalaryStatistics
          salaryStatistics={salaryStatistics}
          salaryIngress={salaryIngress}
        />
      )}

      <Stack direction={{ xs: 'column', lg: 'row' }} gap="0px  24px">
        {/* Genomsnittlig medellön */}
        {sectorSalaryStatistics && (
          <SectorSalaryStatistics
            sectorSalaryStatistics={sectorSalaryStatistics}
            horisontal={horisontal}
          />
        )}

        {/* Antal egenföretagare */}
        {selfEmployedStatistics && (
          <SelfEmployedStatistics
            selfEmployedStatistics={selfEmployedStatistics}
            horisontal={horisontal}
          />
        )}
      </Stack>

      {/* Nuläge */}
      {occupationStatistics && (
        <OccupationStatistics
          occupationStatistics={occupationStatistics}
          occupationStatisticsIngress={occupationStatisticsIngress}
        />
      )}

      {/* Utbildningar som leder till yrket */}
      {occupationEducationStatistics && (
        <OccupationEducationStatistics
          occupationEducationStatistics={occupationEducationStatistics}
        />
      )}

      <Stack direction={{ xs: 'column', lg: 'row' }} gap="0px  24px">
        {/* Anställdas ålder */}
        {occupationAgeStatistics && (
          <OccupationAgeStatistics
            occupationAgeStatistics={occupationAgeStatistics}
            horisontal={horisontal}
          />
        )}

        {/* Arbetsmarknaden */}
        {occupationSectorStatistics && (
          <OccupationSectorStatistics
            occupationSectorStatistics={occupationSectorStatistics}
            horisontal={horisontal}
          />
        )}
      </Stack>
    </>
  )
}

export default ScbStatistics
