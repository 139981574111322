import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  colors,
} from '@mui/material'
import { PropsWithChildren } from 'react'

import { FilterSelectionIndicator } from './FilterSelectionIndicator'

interface FilterOptionGroupAccordionProps extends PropsWithChildren {
  title: string
  selectCount?: number
}

const AccordionStyle = {
  boxShadow: 'none',
  borderTop: 'none',
  '::before': { display: 'none' },
  ':not(:last-child)': {
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}

export const FilterOptionGroupAccordion = ({
  title,
  selectCount = 0,
  children,
}: FilterOptionGroupAccordionProps) => (
  <Accordion sx={AccordionStyle} disableGutters>
    <AccordionSummary expandIcon={<ExpandMore />} aria-controls={title}>
      <Typography>{title}</Typography>
      {selectCount > 0 && (
        <FilterSelectionIndicator>{selectCount}</FilterSelectionIndicator>
      )}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)
