import { isEmpty } from 'ramda'
import { useHistory } from 'react-router-dom'

export const UUIDRegex = /((\w{4,12}-?)){5}/

export const matchStringByRegex = (input: string, matcher: RegExp) =>
  matcher.exec(input)?.toString()?.split(',')[0]

export const matchOldId = (input: string): string => {
  const parts = input.split('/').flatMap((part) => (isEmpty(part) ? [] : part))
  return parts[parts.length - 1]
}

export const useSelectedId = () => {
  const history = useHistory()

  const selectedId =
    matchStringByRegex(history.location.pathname, UUIDRegex) ??
    matchOldId(history.location.pathname)

  return selectedId
}
