import kompetensApi from '../kompetensApi'
import {
  OccupationAgeStatistics,
  OccupationEducationStatistics,
  OccupationSectorStatistics,
  SelfEmployedStatistics,
  SectorSalaryStatistics,
  OccupationGenderStatistics,
} from 'src/features/Yrke/types/OccupationStatistics.types'

export const statisticsApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    getOccupationAgeStatistics: build.query<OccupationAgeStatistics, string>({
      query: (id) => ({
        url: `/statistics/age/${id}`,
      }),
    }),
    getOccupationEducationStatistics: build.query<
      OccupationEducationStatistics,
      string
    >({
      query: (id) => ({
        url: `/statistics/education/${id}`,
      }),
    }),
    getOccupationSectorStatistics: build.query<
      OccupationSectorStatistics,
      string
    >({
      query: (id) => ({
        url: `/statistics/sector/${id}`,
      }),
    }),
    getSelfEmployedStatistics: build.query<SelfEmployedStatistics, string>({
      query: (id) => ({
        url: `/statistics/selfemployed/${id}`,
      }),
    }),
    getSectorSalaryStatistics: build.query<SectorSalaryStatistics, string>({
      query: (id) => ({
        url: `/statistics/salary/${id}`,
      }),
    }),
    getOccupationGenderStatistics: build.query<
      OccupationGenderStatistics,
      string
    >({
      query: (id) => ({
        url: `/statistics/gender/${id}`,
      }),
    }),
  }),
})

export const {
  useGetOccupationAgeStatisticsQuery,
  useGetOccupationEducationStatisticsQuery,
  useGetOccupationSectorStatisticsQuery,
  useGetSelfEmployedStatisticsQuery,
  useGetSectorSalaryStatisticsQuery,
  useGetOccupationGenderStatisticsQuery,
} = statisticsApi
