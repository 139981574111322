import { ArrowUpward } from '@mui/icons-material'
import {
  alpha,
  Box,
  Button,
  Fade,
  styled,
  useScrollTrigger,
  useTheme,
} from '@mui/material'

interface BackToTopProps {
  text: string
  ariaLabel: string
}

export const BackToTop = ({ text, ariaLabel }: BackToTopProps) => {
  const { palette } = useTheme()
  const shadowColor12 = alpha(palette.common.black, 0.12)
  const shadowColor14 = alpha(palette.common.black, 0.14)
  const shadowColor20 = alpha(palette.common.black, 0.2)
  const BlackButton = styled(Button)({
    backgroundColor: palette.common.black,
    color: palette.getContrastText(palette.common.black),
    boxShadow: `0px 3px 14px 2px ${shadowColor12}, 0px 8px 10px 1px ${shadowColor14}, 0px 5px 5px -3px ${shadowColor20}`,

    '&:hover': {
      backgroundColor: palette.grey[800],
    },
    '&:active': {
      backgroundColor: palette.grey[800],
    },
    '&:focus': {
      backgroundColor: palette.grey[800],
    },
  })

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <Fade in={trigger}>
      <Box
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 16,
          left: '50%',
          transform: 'translate(-50%, 0)',
          zIndex: 1000,
        }}
      >
        <BlackButton
          onClick={handleClick}
          variant="contained"
          aria-label={ariaLabel}
          name={ariaLabel}
          endIcon={<ArrowUpward />}
        >
          {text}
        </BlackButton>
      </Box>
    </Fade>
  )
}
