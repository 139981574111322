import { Filter } from 'src/types/Filter.types'

export const studyPaces: Filter[] = [
  {
    name: '25%',
    id: '25',
  },
  {
    name: '50%',
    id: '50',
  },
  {
    name: '75%',
    id: '75',
  },
  {
    name: '100%',
    id: '100',
  },
]
export const other: Filter[] = [
  {
    name: 'Distans',
    id: 'distans',
  },
]

export const mapToDetailedFilterObject = (
  values: {
    other: string[] | undefined
    educationForms: string[] | undefined
    educationTypes: string[] | undefined
    studyPace: string | undefined
  },
  educationFormData: Filter[] | undefined,
  educationTypeData: Filter[] | undefined
) => ({
  other: other?.filter((d) => values.other?.find((v) => v === d.id)),
  educationForms: educationFormData?.filter((d) =>
    values.educationForms?.find((v) => v === d.id)
  ),
  educationTypes: educationTypeData?.filter((d) =>
    values.educationTypes?.find((v) => v === d.id)
  ),
  studyPace: studyPaces.find((el) => el.id === values.studyPace),
})
