export const getImageQuery = (imageUrl: string, width: number): string => {
  const searchParams = new URLSearchParams({
    width: width.toString(),
    dpr: window.devicePixelRatio.toString(),
  })

  return `${imageUrl}?${searchParams.toString()}`
}

export const createImageAltTextFromFileName = (image: string): string => {
  const regexMatchSizeDescriptor = /\d{1,4}[xX]\d{1,4}/

  if (!image || typeof image !== 'string') {
    image = ''
  }

  return (
    image
      .split('/')
      ?.pop()
      ?.split('.')
      ?.shift()
      ?.replaceAll('-', ' ')
      ?.replaceAll('_', ' ')
      ?.replace(regexMatchSizeDescriptor, '')
      ?.trim() ?? ''
  )
}
