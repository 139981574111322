import React, { useEffect } from 'react'
import { Typography } from '@mui/material'

import { usePostFormatTextMutation } from 'src/api/formatText'
import DelayedSkeleton from 'src/components/DelayedSkeleton/DelayedSkeleton'
import { truncateText } from 'src/utils/helpers/truncateText'
import { useTranslation } from 'react-i18next'

interface EducationDescriptionProps {
  text: string | undefined
  id: string | undefined
  lastEdited?: string
  showFormattedText: boolean
  isInView?: boolean
}

const EducationSummary: React.FC<EducationDescriptionProps> = ({
  text,
  id,
  lastEdited,
  showFormattedText,
  isInView,
}) => {
  const [formatText, res] = usePostFormatTextMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'utbildning' })
  useEffect(() => {
    if (
      !res.data &&
      !res.isLoading &&
      text &&
      id &&
      showFormattedText &&
      isInView
    )
      void formatText({
        id,
        text,
        lastUpdated: lastEdited ?? undefined,
      })
  }, [text, res, formatText, id, lastEdited, showFormattedText, isInView])

  const summary = showFormattedText
    ? res?.data?.summary
    : truncateText(text, 350, '..')

  if (res.isLoading && showFormattedText)
    return <DelayedSkeleton variant="rounded" width={'100%'} height={70} />
  if (!text && !res.isLoading)
    return <Typography variant="body1">{t('noDesc')}</Typography>
  if (summary)
    return (
      <Typography gutterBottom variant="subtitle2" component="p">
        {summary}
      </Typography>
    )
  return null
}

export default EducationSummary
