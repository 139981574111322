import React, { useRef } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  Chip,
  Select,
  MenuItem,
  type SelectChangeEvent,
  useTheme,
  Box,
  Typography,
  Stack,
} from '@mui/material'

export interface MenuItem {
  name: string
  value: string
}

interface ChipSelectProps {
  value: string | null
  label: string
  open: boolean
  menuItems?: MenuItem[] | undefined
  onChange: (event: SelectChangeEvent) => void
  onClick: () => void
}

const ChipSelect: React.FC<ChipSelectProps> = ({
  value,
  label,
  open,
  menuItems,
  onChange,
  onClick,
}) => {
  const { palette } = useTheme()
  const chipRef = useRef<HTMLDivElement>(null)

  return (
    <Box>
      <Chip
        ref={chipRef}
        data-testid="chip-select"
        variant="outlined"
        label={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: '100%',
              px: 1,
            }}
          >
            <Typography variant="body2">{label}</Typography>
            {open ? (
              <ArrowDropUp
                data-testid="arrow-drop-up"
                onClick={onClick}
                sx={{ color: palette.grey[700] }}
              />
            ) : (
              <ArrowDropDown
                data-testid="arrow-drop-down"
                onClick={onClick}
                sx={{ color: palette.grey[700] }}
              />
            )}
          </Stack>
        }
        onClick={onClick}
        sx={{
          backgroundColor: palette.background.paper,
          width: {
            xs: '100%',
            sm: 'auto',
          },
          height: '40px',
          borderRadius: '40px',
          '& .MuiChip-label': {
            padding: 0,
            width: '100%',
          },
        }}
      />
      <Select
        open={open}
        value={value ?? ''}
        onChange={onChange}
        onClick={onClick}
        MenuProps={{
          anchorEl: chipRef.current,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          sx: {
            marginTop: '8px',
          },
        }}
        sx={{
          display: 'none',
          width: {
            xs: '100%',
            sm: 'auto',
          },
        }}
      >
        {menuItems?.map((menuItem) => (
          <MenuItem
            data-testid="chip-select-item"
            key={menuItem.value}
            value={menuItem.value}
          >
            {menuItem.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default ChipSelect
