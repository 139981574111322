import { alpha, Stack, Typography, useTheme } from '@mui/material'

import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import Chart from './Chart'
import type { OccupationSectorStatistics as OccupationSectorStatisticsType } from 'src/features/Yrke/types/OccupationStatistics.types'
import { useEpiContent } from '@trr/app-shell-data'
import { useTranslation } from 'react-i18next'
import convertToStatistics from 'src/utils/helpers/convertToStatistics'
import { Content } from 'src/App/App.types'

interface OccupationSectorStatisticsProps {
  occupationSectorStatistics: OccupationSectorStatisticsType
  horisontal: boolean
}

const OccupationSectorStatistics = ({
  occupationSectorStatistics,
  horisontal,
}: OccupationSectorStatisticsProps) => {
  const { palette } = useTheme()
  const {
    yrke: { sectorHeader, sectorPreamble },
  } = useEpiContent<Content>()
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tScbStatistik } = useTranslation('translation', {
    keyPrefix: 'scbStatistik',
  })

  const sectorLabelsAndColors = [
    {
      label: tScbStatistik('sectorPrivate'),
      color: palette.secondary.main,
      key: 'private',
    },
    {
      label: tScbStatistik('sectorNonProfit'),
      color: palette.error.main,
      key: 'nonProfit',
    },
    {
      label: tScbStatistik('sectorState'),
      color: palette.accent?.dark ?? '',
      key: 'state',
    },
    {
      label: tScbStatistik('sectorMunicipality'),
      color: palette.primary.main,
      key: 'municipality',
    },
    { label: tScbStatistik('sectorRegion'), color: '#3560FF', key: 'region' },
    {
      label: tScbStatistik('sectorOther'),
      color: palette.text.secondary,
      key: 'other',
    },
  ]

  const sectorChartData = occupationSectorStatistics
    ? convertToStatistics(occupationSectorStatistics, sectorLabelsAndColors)
    : []

  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''

  if (!occupationSectorStatistics) return null

  return (
    <Stack
      sx={{
        backgroundColor: palette.surface?.blue,
        p: { xs: 3, sm: 5 },
        mt: { xs: 0, sm: 3 },
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: '0 0 8px 8px', sm: 2 }}
      flex={{ xs: '1', md: horisontal ? 'auto' : '1' }}
      direction="column"
      justifyContent="space-between"
    >
      <Stack
        direction={{ xs: 'column', md: horisontal ? 'column' : 'row' }}
        gap="8px  40px"
        height="100%"
      >
        <Stack
          flex={{
            md: horisontal ? 'auto' : '2',
            lg: horisontal ? 'auto' : '1.2',
          }}
        >
          <Typography variant="h4" component="h3">
            {sectorHeader}
          </Typography>
          <Typography variant="body1" component="p">
            {sectorPreamble}
          </Typography>
          <Typography variant="body1" component="p">
            {tScbStatistik('sectorInfo')}
          </Typography>
        </Stack>
        <Chart data={sectorChartData} horisontal={horisontal} />
      </Stack>
      <SourceMetaText
        text={`${tKallor('kallaSCB')} (${occupationSectorStatistics.year?.toString() ?? ''}).`}
      />
    </Stack>
  )
}

export default OccupationSectorStatistics
