import React, { useState } from 'react'
import {
  alpha,
  Box,
  type SelectChangeEvent,
  Stack,
  capitalize,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material'
import OutlookBarChart from '../OutlookBarChart/OutlookBarChart'
import PrognosCard from '../PrognosCard/PrognosCard'
import SourceMetaText from '../SourceMetaText/SourceMetaText'
import ChipSelect, {
  MenuItem,
} from '../../../../components/ChipSelect/ChipSelect'
import type { OccupationOutlook } from '../../types/OccupationOutlook.types'
import {
  useGetAllCountiesQuery,
  useGetOccupationOutlooksQuery,
} from 'src/api/referenceData'
import { useTranslation } from 'react-i18next'
import OutlookNullState from '../OutlookNullState/OutlookNullState'
import { COUNTRY_WIDE_CODE } from 'src/utils/constants/constants'

interface OutlookProps {
  occupationOutlook: OccupationOutlook
}
const Outlook: React.FC<OutlookProps> = ({
  occupationOutlook: countryOutlook,
}) => {
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tAfStatistik } = useTranslation('translation', {
    keyPrefix: 'afStatistik',
  })
  const { t: tRapport } = useTranslation('translation', {
    keyPrefix: 'rapport',
  })
  const { palette } = useTheme()
  const borderColor = alpha(palette.neutral?.main ?? 'rgb()', 0.16)
  const { data: regions } = useGetAllCountiesQuery()
  const menuItems = [
    { name: tRapport('nationell'), value: COUNTRY_WIDE_CODE },
    ...(regions?.map((region) => ({
      name: region.label,
      value: region.id,
    })) || []),
  ] as MenuItem[]

  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(COUNTRY_WIDE_CODE)
  const handleRegionChange = (event: SelectChangeEvent) => {
    setSelectedRegion(event.target.value)
  }
  const handleOnClick = () => {
    setIsSelectOpen(!isSelectOpen)
  }

  const paddedLan = selectedRegion.toString().padStart(2, '0')
  const { data: regionalOutlook = {} as OccupationOutlook, isFetching } =
    useGetOccupationOutlooksQuery(
      {
        ssyk: countryOutlook?.ssyk ?? '',
        occupationId: countryOutlook.occupationId,
        lan: paddedLan,
      },
      {
        skip: !countryOutlook?.ssyk || selectedRegion === COUNTRY_WIDE_CODE,
      }
    )

  const currentOutlook =
    selectedRegion === COUNTRY_WIDE_CODE ? countryOutlook : regionalOutlook
  const currentOutlookHasData =
    currentOutlook?.outlookValue && currentOutlook?.prognos
  const sourceMetaText = `${tKallor('kallaAF')}${currentOutlook?.year ? ` (${currentOutlook.year})` : ''}.`
  if (!countryOutlook) return null

  return (
    <Stack
      spacing={3}
      sx={{
        padding: { xs: 2, sm: 5 },
        mt: { xs: 0, sm: 0.5 },
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
        backgroundColor: palette.surface?.blue,
      }}
      borderRadius={2}
    >
      <Stack>
        <Box>
          <Typography gutterBottom variant="h4" component="h3">
            {tAfStatistik('mojligheterTillArbete')}
          </Typography>
          <Typography gutterBottom variant="body1">
            {tAfStatistik('mojligheterInfo')}
          </Typography>
          <ChipSelect
            open={isSelectOpen}
            value={selectedRegion}
            label={capitalize(
              menuItems.find((item) => item.value === selectedRegion)?.name ??
                ''
            )}
            menuItems={menuItems}
            onChange={handleRegionChange}
            onClick={handleOnClick}
          />
        </Box>
      </Stack>
      {isFetching ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : currentOutlookHasData ? (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          <Box
            key={currentOutlook?.id + '1'}
            borderRadius={{ xs: 0, sm: 2 }}
            flex="1 1 50%"
          >
            <OutlookBarChart occupationOutlook={currentOutlook} />
          </Box>

          {currentOutlook && (
            <Box key={currentOutlook.id} flex="1 1 50%">
              <PrognosCard occupationOutlook={currentOutlook} />
            </Box>
          )}
        </Stack>
      ) : (
        <OutlookNullState
          onLinkClick={() => {
            setSelectedRegion(COUNTRY_WIDE_CODE)
          }}
        />
      )}
      <SourceMetaText text={sourceMetaText} />
    </Stack>
  )
}

export default Outlook
