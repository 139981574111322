import { Typography, useTheme } from '@mui/material'

interface SourceMetaTextProps {
  text?: string
}

const SourceMetaText = ({ text }: SourceMetaTextProps) => {
  const { palette } = useTheme()
  if (!text) return null
  return (
    <Typography sx={{ mt: 2 }} color={palette.grey[700]} variant="body2">
      {text}
    </Typography>
  )
}

export default SourceMetaText
