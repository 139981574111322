import { Money, SwapVert } from '@mui/icons-material'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import {
  useGetOccupationOutlooksQuery,
  useGetSalaryStatisticsQuery,
} from 'src/api/referenceData'
import { IconText } from 'src/components/IconText/IconText'
import SourceMetaText from '../SourceMetaText/SourceMetaText'
import type { OccupationOutlook } from '../../types/OccupationOutlook.types'
import { useTranslation } from 'react-i18next'
import { routes } from 'src/utils/router/Routes'
import { COUNTRY_WIDE_CODE } from 'src/utils/constants/constants'
import React from 'react'

interface OccupationCardProps {
  header: string
  subheader?: string
  id: string
  occupationGroupId?: string
  occupationGroupLabel?: string
  ssyk?: string
  headerComponent: string
}

const OccupationCard = ({
  id,
  header,
  subheader,
  headerComponent,
  ssyk,
}: OccupationCardProps) => {
  const { data: occupationalOutlook = {} as OccupationOutlook } =
    useGetOccupationOutlooksQuery(
      {
        ssyk: ssyk ?? '',
        occupationId: id,
        lan: COUNTRY_WIDE_CODE,
      },
      {
        skip: !ssyk,
      }
    )

  const { data: salaryStatistics } = useGetSalaryStatisticsQuery(ssyk ?? '', {
    skip: !ssyk,
  })

  const { t: tYrke } = useTranslation('translation', { keyPrefix: 'yrke' })
  const { t: tKallor } = useTranslation('translation', { keyPrefix: 'kallor' })

  const sourceText = () => {
    return occupationalOutlook?.year && salaryStatistics?.year
      ? tKallor('kallor', {
          scbYear: salaryStatistics?.year.toString(),
          afYear: occupationalOutlook?.year,
        }) // Vi har data från både arbetsförmedlingen (§) och scb (salaryStatistics)
      : occupationalOutlook?.year
        ? `${tKallor('kallaAF')} (${occupationalOutlook?.year})` // Vi har bara data från arbetsförmedlingen (occupationalOutlook)
        : salaryStatistics?.year
          ? `${tKallor('kallaSCB')} (${salaryStatistics?.year.toString()})`
          : '' // Vi har bara data från arbetsförmedlingen (salaryStatistics)
  }

  const HeaderComponent = ({ ...props }) =>
    React.createElement(headerComponent, props)
  const ariaLabel = `${header}, ${subheader ?? ''}`

  return (
    <Card
      data-testid="occupation-card"
      key={id}
      component="article"
      sx={{ marginTop: 3, backgroundColor: 'transparent' }}
    >
      <CardActionArea
        component={Link}
        href={`${routes.start}yrke/${id}`}
        aria-label={ariaLabel}
        data-gtm-label={header}
      >
        <CardHeader
          title={
            <Typography
              variant="h5"
              component={HeaderComponent}
              sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
            >
              {header}
            </Typography>
          }
          subheader={subheader}
          subheaderTypographyProps={{ component: 'p' }}
        />
        <CardContent>
          <Stack
            sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
            gap={{ xs: 1, sm: 2 }}
            marginBottom={{ xs: 1, sm: 0 }}
          >
            {occupationalOutlook?.outlookValue ? (
              <>
                <IconText
                  key={occupationalOutlook.id}
                  label={occupationalOutlook.outlookLabel}
                  subLabel={`${tYrke('tillArbeteAr')} ${occupationalOutlook.year}`}
                  icon={<SwapVert />}
                />
                <IconText
                  label={
                    occupationalOutlook.prognos === 'vara oförändrad'
                      ? tYrke('oforandradEfterfragan')
                      : occupationalOutlook.prognos === 'minska'
                        ? tYrke('minskandeEfterfragan')
                        : tYrke('okandeEfterfragan')
                  }
                  subLabel={tYrke('femArsSikt')}
                  icon={<SwapVert />}
                />
              </>
            ) : (
              <>
                <IconText
                  disabled
                  label={tYrke('saknarData')}
                  subLabel={`${tYrke('tillArbeteAr')} ${new Date().getFullYear().toString()}`}
                  icon={<SwapVert />}
                />
                <IconText
                  disabled
                  label={tYrke('saknarData')}
                  subLabel={tYrke('femArsSikt')}
                  icon={<SwapVert />}
                />
              </>
            )}
            {salaryStatistics && (
              <IconText
                label={`${salaryStatistics?.percentile10?.toLocaleString(
                  'sv-SE'
                )} - ${salaryStatistics?.percentile90?.toLocaleString(
                  'sv-SE'
                )}kr/mån`}
                subLabel={`${tYrke('medellon')} ${salaryStatistics.average.toLocaleString(
                  'sv-SE'
                )} ${tYrke('kr')}`}
                icon={<Money />}
              />
            )}
          </Stack>

          <SourceMetaText text={sourceText()} />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OccupationCard
