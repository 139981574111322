import {
  alpha,
  capitalize,
  type SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChipSelect, { MenuItem } from 'src/components/ChipSelect/ChipSelect'
import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import type { SelfEmployedStatistics as SelfEmployedStatisticsType } from 'src/features/Yrke/types/OccupationStatistics.types'
import { useTranslation } from 'react-i18next'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'src/App/App.types'

interface SelfEmployedStatisticsProps {
  selfEmployedStatistics: SelfEmployedStatisticsType | undefined
  horisontal?: boolean
}

const SelfEmployedStatistics: React.FC<SelfEmployedStatisticsProps> = ({
  selfEmployedStatistics,
  horisontal,
}) => {
  const { yrke } = useEpiContent<Content>()
  const { palette } = useTheme()
  const { t: tYrke } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tScbStatistik } = useTranslation('translation', {
    keyPrefix: 'scbStatistik',
  })

  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''

  const [selfEmployedValue, setSelfEmployedValue] = useState<string | null>('')
  const [selfEmployedOpen, setSelfEmployedOpen] = useState(false)
  const [selfEmployed, setSelfEmployed] = useState(0)
  const onSelfEmployedSector = (event: SelectChangeEvent) => {
    setSelfEmployedValue(event.target.value)
  }
  const handleOnSelfEmployedClick = () => {
    setSelfEmployedOpen(selfEmployedOpen ? false : true)
  }
  const genderMenuItems = [
    {
      name: 'Samtliga kön',
      value: '',
    },
    {
      name: capitalize(tYrke('women') ?? ''),
      value: 'women',
    },
    {
      name: capitalize(tYrke('men') ?? ''),
      value: 'men',
    },
  ] as MenuItem[]

  useEffect(() => {
    let selectedValue =
      (selfEmployedStatistics?.men ?? 0) + (selfEmployedStatistics?.women ?? 0)
    if (selfEmployedValue === 'men') {
      selectedValue = selfEmployedStatistics?.men ?? 0
    } else if (selfEmployedValue === 'women') {
      selectedValue = selfEmployedStatistics?.women ?? 0
    }
    setSelfEmployed(selectedValue)
  }, [selfEmployedStatistics, selfEmployedValue])

  if (!selfEmployedStatistics) return null

  return (
    <Stack
      sx={{
        backgroundColor: palette.surface?.blue,
        p: { xs: 3, sm: 5 },
        mt: { xs: 0, sm: 3 },
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: 0, sm: 2 }}
      flex={{ xs: '1', md: horisontal ? 'auto' : '1' }}
      direction="column"
      justifyContent="space-between"
    >
      <Typography variant="h4" component="h3">
        {yrke.selfEmployedHeader}
      </Typography>
      <Typography variant="body1" component="p" marginBottom={3}>
        {yrke.selfEmployedPreamble}
      </Typography>
      <ChipSelect
        value={selfEmployedValue}
        label={capitalize(
          genderMenuItems.find((item) => item.value === selfEmployedValue)
            ?.name ?? ''
        )}
        open={selfEmployedOpen}
        menuItems={genderMenuItems}
        onChange={onSelfEmployedSector}
        onClick={handleOnSelfEmployedClick}
      />
      <Typography variant="h3" component="p" marginTop={2}>
        {selfEmployed} {tScbStatistik('st')}
      </Typography>
      <SourceMetaText
        text={`${tKallor('kallaSCB')} (${selfEmployedStatistics.year}).`}
      />
    </Stack>
  )
}

export default SelfEmployedStatistics
