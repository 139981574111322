import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import type { OccupationOutlook } from '../../types/OccupationOutlook.types'

interface OutlookBarChartProps {
  occupationOutlook: OccupationOutlook
}

const OutlookBarChart = ({ occupationOutlook }: OutlookBarChartProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })
  const { palette } = useTheme()
  const steps = [
    {
      percentage: '25',
      mobileHeight: '16',
      active: occupationOutlook.outlookValue === 25,
    },
    {
      percentage: '50',
      mobileHeight: '32',
      active: occupationOutlook.outlookValue === 50,
    },
    {
      percentage: '75',
      mobileHeight: '48',
      active: occupationOutlook.outlookValue === 75,
    },
  ]
  const arialabel = `${occupationOutlook.outlookLabel ?? ''} ${t('tillArbete')} ${
    occupationOutlook.year
  }`

  return (
    <Box>
      <Stack
        direction="row"
        gap={{ xs: 2, sm: 6 }}
        role="figure"
        aria-label={arialabel}
        alignItems={'center'}
        mb={2}
        height={'90px'}
      >
        <Stack
          alignItems={'flex-end'}
          gap={{ xs: 0.5, sm: 1.5 }}
          direction="row"
        >
          {steps.map((o) => (
            <Box
              key={o.percentage}
              sx={{
                borderRadius: { xs: '3px', sm: '5px' },
                width: { xs: '20px', sm: '50px' },
                height: { xs: o.mobileHeight + 'px', sm: o.percentage + 'px' },
                backgroundColor: o.active
                  ? palette.info.main
                  : palette.info.light,
              }}
            />
          ))}
        </Stack>
        <Stack flexGrow={1}>
          <Typography variant="h5" component="p">
            {occupationOutlook.outlookLabel}
          </Typography>
          <Typography variant="body1">
            {`${t('tillArbete')} ${occupationOutlook.year}`}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="body2" color="text.secondary" mb={1}>
        {occupationOutlook.ingress}
      </Typography>
    </Box>
  )
}

export default OutlookBarChart
