import { Pagination, Stack, Box, Collapse } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'
import { isEmpty } from 'ramda'
import { HiddenText } from 'src/components/HiddenText/HiddenText'
import { ApplicationState } from 'src/store'
import { updatePage } from 'src/api/slices/educationFilterSlice'
import { Education } from '../../types/Education.types'
import EducationCard from '../EducationCard/EducationCard'
import { useTranslation } from 'react-i18next'

interface EducationsListProps {
  educations: Education[]
  noResult?: React.ReactNode | null
  showTypeToggle: boolean
  itemHeaderComponent: string
}

const EducationsList = ({
  educations,
  noResult,
  showTypeToggle,
  itemHeaderComponent,
}: EducationsListProps) => {
  const dispatch = useDispatch()
  const page = useSelector(
    (state: ApplicationState) => state.educationFilter.page
  )
  const { t } = useTranslation('translation', { keyPrefix: 'utbildningar' })

  if (!showTypeToggle && educations.length < 1) return <>{noResult}</>

  return (
    <Box sx={{ mt: 3 }}>
      {isEmpty(educations) ? (
        <>{noResult}</>
      ) : (
        <>
          <HiddenText>
            {educations.length} {t('hits')}
          </HiddenText>

          <TransitionGroup>
            {educations.length > 0 &&
              educations?.slice(page * 10, page * 10 + 10)?.map((r) => {
                if (r.id)
                  return (
                    <Collapse key={r.id}>
                      <EducationCard
                        id={r.id}
                        headerComponent={itemHeaderComponent}
                        education={r}
                      />
                    </Collapse>
                  )
              })}
          </TransitionGroup>
          {educations.length < 1 && <>{noResult}</>}
          {educations?.length > 10 && (
            <Stack
              sx={{ mt: 3 }}
              justifyContent="center"
              direction="row"
              spacing={2}
            >
              <Pagination
                data-testid="educations-list-pagination"
                page={page + 1}
                onChange={(e, v) => {
                  dispatch(updatePage(v - 1))
                  window.scrollTo(0, 100)
                }}
                count={Math.ceil(educations.length / 10)}
              />
            </Stack>
          )}
        </>
      )}
    </Box>
  )
}

export default EducationsList
